<template>
  <div>
    <v-dialog v-model="dialog"
              persistent
              scrollable
              width="600"
    >

      <v-card :loading="isLoading">

        <v-card-title>
          E-mails envoyés
          <v-spacer/>
          <v-icon @click="close">mdi-close</v-icon>
        </v-card-title>
        <v-divider/>
        <v-card-text class="pa-8">

          <div class="mb-3 text-center">
            <v-btn text color="grey darken-1"
                   @click="getBoostingMails(boosting)">
              <v-icon left>mdi-refresh</v-icon>
              Actulaiser
            </v-btn>
          </div>

          <v-skeleton-loader v-if="isLoading" type="article"></v-skeleton-loader>

          <v-simple-table v-if="!isLoading && emails.length" class="table-border">
            <template v-slot:default>
              <thead>
              <tr>
                <th>Email</th>
                <th>Date</th>
                <th>Statut</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item,i) in emails" :key="i">
                <td class="font-weight-medium">{{ item.email }}</td>
                <td>{{ item.created_at }}</td>
                <td>
                  <v-chip v-if="item.status === 'done'" color="success" dark small>
                    <v-icon left small>mdi-check</v-icon>
                    Envoyé
                  </v-chip>
                  <v-chip v-if="item.status === 'error'" color="red" dark small>
                    <v-icon left small>mdi-close</v-icon>
                    Échec
                  </v-chip>
                  <v-chip v-if="item.status === 'pending'" color="warning" dark small>
                    <v-icon left small>mdi-timer-sand</v-icon>
                    En cours
                  </v-chip>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>

          <div v-if="!isLoading && !emails.length"
               class="text-center font-weight-medium py-5">
            <v-icon size="70">mdi-email-receive</v-icon>
            <p>
              Aucun email envoyé !
            </p>
          </div>

        </v-card-text>
      </v-card>

    </v-dialog>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
   data() {
      return {
         dialog: false,
         isLoading: false,
         boosting: {},
         emails: [],
      }
   },
   methods: {
      open(boosting) {
         this.emails = []
         this.boosting = boosting
         this.getBoostingMails(boosting)
         this.dialog = true
      },
      close() {
         this.dialog = false
      },
      getBoostingMails(boosting) {
         this.isLoading = true
         HTTP.get('/v1/boostings/' + boosting.id + '/mails').then(res => {
            this.isLoading = false
            this.emails = res.data.data
         }).catch(err => {
            this.isLoading = false
            console.log(err)
         })
      },
   },
}
</script>

