<template>
  <div>
    <v-dialog v-model="dialog"
              persistent
              scrollable
              width="500"
    >

      <form @submit.prevent="saveBoosting">
        <v-card :disabled="isLoading" :loading="isLoading">

          <v-card-title>
            Envoyer un fichier excel par email
            <v-spacer/>
            <v-icon @click="close">mdi-close</v-icon>
          </v-card-title>
          <v-divider/>

          <v-card-text class="pa-8">

            <v-text-field v-model="email"
                          :error-messages="errors.email"
                          label="Email *"
                          type="email"/>
          </v-card-text>

          <v-divider/>
          <v-card-actions>
            <v-spacer/>
            <v-btn :loading="isLoading"
                   color="primary"
                   depressed
                   type="submit">
              <v-icon left>mdi-send</v-icon>
              Envoyer
            </v-btn>
          </v-card-actions>

        </v-card>
      </form>

    </v-dialog>
  </div>
</template>

<script>

import {HTTP} from "@/http-common";

export default {
   data() {
      return {
         dialog: false,
         isLoading: false,
         errors: {},

         boostingId: '',
         email: '',
      }
   },
   methods: {
      open(boostingId) {
         this.dialog = true
         this.boostingId = boostingId
         this.email = ''
         this.errors = {}
      },
      close() {
         this.dialog = false
      },
      saveBoosting() {
         this.errors = {}
         this.isLoading = true
         HTTP.post('/v1/boostings/send-mail', {
            email: this.email,
            boosting_id: this.boostingId,
         }).then(() => {
            this.isLoading = false
            this.dialog = false
            this.$successMessage = "L'opération a été enregistrée avec succès. Le fichier Excel sera envoyé à cette adresse email"
         }).catch(err => {
            this.isLoading = false
            this.errors = err.response.data.errors
            this.$errorMessage = "Une erreur s'est produite veuillez corriger et réessayer."
            console.log(err)
         })

      },
   },
}
</script>

