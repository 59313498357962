<template>
  <div>
    <v-container fluid>

      <div class="main">

        <v-row v-if="isLoading">
          <v-col v-for="item in 4" :key="item" cols="12" lg="3">
            <v-card class="rounded-lg shadow">
              <v-card-text>
                <v-skeleton-loader type="list-item-avatar-three-line"></v-skeleton-loader>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <div v-else>
          <v-row>
            <v-col cols="12" lg="3">
              <v-card class="rounded-lg shadow">
                <v-card-text class="py-5">
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-avatar color="gifty" size="45">
                        <v-icon color="white">mdi-clipboard-list-outline</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="9">
                      <div class="font-weight-black title pb-1 grey--text text--darken-3">
                        {{ boostings.length }}
                      </div>
                      Total campagnes
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" lg="3">
              <v-card class="rounded-lg shadow">
                <v-card-text class="py-5">
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-avatar color="info" size="45">
                        <v-icon color="white">mdi-file-code-outline</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="9">
                      <div class="font-weight-black title pb-1 grey--text text--darken-3">
                        {{ totalCode() }}
                      </div>
                      Total codes générés
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" lg="3">
              <v-card class="rounded-lg shadow">
                <v-card-text class="py-5">
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-avatar color="success" size="45">
                        <v-icon color="white">mdi-file-check-outline</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="9">
                      <div class="font-weight-black title pb-1 grey--text text--darken-3">
                      0
                      </div>
                      Total codes utilisés
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" lg="3">
              <v-card class="rounded-lg shadow">
                <v-card-text class="py-5">
                  <v-row align="center" justify="center">
                    <v-col cols="3">
                      <v-avatar color="warning" size="45">
                        <v-icon color="white">mdi-file-clock-outline</v-icon>
                      </v-avatar>
                    </v-col>
                    <v-col cols="9">
                      <div class="font-weight-black title pb-1 grey--text text--darken-3">
                        {{ totalCode() }}
                      </div>
                      Total codes restants
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <div class="text-end my-3">
          <v-btn class="rounded-lg"
                 color="gifty"
                 dark
                 depressed
                 @click="$refs.boostingFormDialog.open()"
          >
            <v-icon left>mdi-plus</v-icon>
            Générer des codes
          </v-btn>
        </div>

        <v-card class="rounded-lg shadow">

          <v-overlay :value="overlay"
                     absolute
                     color="white"
                     opacity="0.5">
            <v-progress-circular
                    color="primary"
                    indeterminate
                    size="50"
            ></v-progress-circular>
          </v-overlay>

          <v-card-title>
            <div>
              <v-text-field
                      v-model="keyword"
                      append-icon="mdi-magnify"
                      clearable
                      dense
                      filled
                      hide-details
                      placeholder="Recherche..."
                      rounded
                      single-line
              ></v-text-field>
            </div>

            <v-spacer/>

            <v-btn color="grey"
                   icon
                   @click="getBoostings">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>

          </v-card-title>

          <v-divider/>

          <v-card-text v-if="isLoading" class="pa-0">
            <v-skeleton-loader type="table"/>
          </v-card-text>

          <div v-else>

            <v-card-text class="pa-0">
              <v-data-table v-if="boostings.length"
                            :headers="headers"
                            :items="boostings"
                            :search="keyword"
              >

                <template v-slot:item.actions="{ item }">
                <div class="d-flex align-center">
                  <v-btn icon @click="$refs.boostingMailsDialog.open(item)">
                    <v-icon>mdi-email-receive</v-icon>
                  </v-btn>
                  <v-btn color="primary"
                         class="ml-1"
                         :disabled="!(item.status === 'done')"
                         :dark="(item.status === 'done')"
                         depressed
                         fab
                         x-small
                         @click="$refs.boostingMailFormDialog.open(item.id)">
                    <v-icon>mdi-email-send-outline</v-icon>
                  </v-btn>
                </div>
                </template>


                <template v-slot:item.boosting_codes_count="{ item }">


                  <div class="d-flex align-center justify-space-between mb-1">
                    <span class="blue--text font-weight-medium">
                       {{ ((item.boosting_codes_count * 100) / item.nb_codes).toFixed(0) }}%
                    </span>
                    <v-spacer/>
                    {{ item.boosting_codes_count }}
                  </div>

                  <v-progress-linear :value="(item.boosting_codes_count * 100) / item.nb_codes"
                                     color="blue"
                                     height="7"
                                     rounded
                                     striped
                  ></v-progress-linear>
                </template>

                <template v-slot:item.status="{ item }">
                  <v-chip v-if="item.status === 'done'" color="success" dark small>
                    <v-icon left small>mdi-check</v-icon>
                    Complété
                  </v-chip>
                  <v-chip v-if="item.status === 'error'" color="red" dark small>
                    <v-icon left small>mdi-close</v-icon>
                    Échec
                  </v-chip>
                  <v-chip v-if="item.status === 'pending'" color="warning" dark small>
                    <v-icon left small>mdi-timer-sand</v-icon>
                    En cours
                  </v-chip>
                </template>

                <template v-slot:item.created_at="{ item }">
                  <div class="text-no-wrap">
                    <v-icon :style="{marginTop:'-3px'}"
                            color="gifty"
                            small>mdi-clock-outline
                    </v-icon>
                    {{ item.created_at }}
                  </div>
                </template>

              </v-data-table>

              <div v-else>
                <NoResults @reload="getBoostings"/>
              </div>

            </v-card-text>
          </div>
        </v-card>

      </div>

    </v-container>

    <BoostingFormDialog ref="boostingFormDialog" @refresh="getBoostings"/>
    <BoostingMailFormDialog ref="boostingMailFormDialog"/>
    <BoostingMailsDialog ref="boostingMailsDialog"/>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import BoostingFormDialog from "@/views/boostings/components/BoostingFormDialog.vue";
import BoostingMailFormDialog from "@/views/boostings/components/BoostingMailFormDialog.vue";
import BoostingMailsDialog from "@/views/boostings/components/BoostingMailsDialog.vue";

export default {
   components: {
      BoostingFormDialog,
      BoostingMailFormDialog,
      BoostingMailsDialog,
      NoResults,
   },
   data() {
      return {
         id: '',
         keyword: '',
         overlay: false,
         isLoading: false,
         boostings: [],

         headers: [
            {text: 'Nom de la campagne', value: 'name'},
            {text: 'NB de Caractères', value: 'nb_characters'},
            {text: 'NB de Codes', value: 'nb_codes'},
            {text: 'Statut de génération', value: 'status'},
            {text: 'Progression de génération', value: 'boosting_codes_count'},
            {text: 'Créé le', value: 'created_at'},
            {text: 'Actions', value: 'actions', sortable: false},
         ]
      };
   },
   methods: {
      getBoostings(spinner) {
         if (spinner !== 'noSpinner') {
            this.isLoading = true
         }
         HTTP.get('/v1/boostings').then(res => {
            this.isLoading = false
            this.boostings = res.data.data
         }).catch(err => {
            this.isLoading = false
            console.log(err)
         })
      },
      totalCode(){
         return this.boostings.reduce((acc, val) => {
            return acc + parseInt(val.boosting_codes_count)
         }, 0)
      }
   },
   created() {
      this.getBoostings()

      /**
       * Refresh every 4 second
       */
      let _vm = this
      setInterval(function () {
        let countPending = _vm.boostings.filter(el => el.status === 'pending').length
         if (countPending){
            _vm.getBoostings('noSpinner')
         }
      }, 4000)
   }
};
</script>

<style scoped>
</style>
